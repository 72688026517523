import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth/LoginView.vue'),
    meta: { layout: "authLayout" }
  },

  {
    path: '/signUp',
    name: 'SignUp',
    component: () => import('../views/Auth/SignUp/SignUp.vue'),
    meta: { layout: "authLayout" }
  },

  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: () => import('../views/Auth/ForgotPassword.vue'),
    meta: { layout: "authLayout" }
  }, 

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard/Dashboard.vue'),
    meta: { layout: "defaultLayout" }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products/Products.vue'),
    meta: { layout: "defaultLayout" }
  },

  {
    path: '/bookings',
    name: 'Bookings',
    component: () => import('../views/Bookings/BookingsList.vue'),
    meta: { layout: "defaultLayout" },
  },  

  {
    path: '/booking-event',
    name: 'Bookings Event',
    component: () => import('../views/Bookings/Details/BookingEvent.vue'),
    meta: { layout: "defaultLayout" },
  },
  {
    path: '/booking-activity',
    name: 'Bookings-Activity',
    component: () => import('../views/Bookings/Details/BookingActivity.vue'),
    meta: { layout: "defaultLayout" },
  },
  {
    path: '/booking-package',
    name: 'Bookings-Package',
    component: () => import('../views/Bookings/Details/BookingPackage.vue'),
    meta: { layout: "defaultLayout" },
  },

  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import('../views/Transactions/Transactions.vue'),
    meta: { layout: "defaultLayout" }
  },

  {
    path: '/business',
    name: 'MyBusiness',
    component: () => import('../views/MyBusiness/MyBusiness.vue'),
    meta: { layout: "defaultLayout" }
  },

  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings/Settings.vue'),
    meta: { layout: "defaultLayout" }
  },

  {
    path: '/activity',
    name: 'Activity',
    component: () => import('../views/ProductCategory/Activity/AddActivity.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/activity1',
    name: 'Activity1',
    component: () => import('../views/ProductCategory/Activity/AddNewActivity.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/activity2',
    name: 'Activity2',
    component: () => import('../views/ProductCategory/Activity/AddNewActivitystep2.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/activity3',
    name: 'Activity3',
    component: () => import('../views/ProductCategory/Activity/AddNewActivitystep3.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/activity4',
    name: 'Activity4',
    component: () => import('../views/ProductCategory/Activity/AddNewActivitystep4.vue'),
    meta: { layout: "authLayout" }
  },

  {
    path: '/events',
    name: 'Events',
    component: () => import('../views/ProductCategory/Events/AddEvent.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/add-new-ticket-events',
    name: 'New Ticket Event',
    component: () => import('../views/ProductCategory/Events/AddNewTicketEvent.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/add-new-ticket-events2',
    name: 'New Ticket Event2',
    component: () => import('../views/ProductCategory/Events/AddTimeAndTicketEvent.vue'),
    meta: { layout: "authLayout" }
  },

  {
    path: '/add-new-ticket-events3',
    name: 'New Ticket Event3',
    component: () => import('../views/ProductCategory/Events/AddNewTicketEvent3.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/add-new-ticket-events4',
    name: 'New Ticket Event4',
    component: () => import('../views/ProductCategory/Events/AddNewTicketEvent4.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/add-new-ticket-event-review',
    name: 'New Ticket Event Review',
    component: () => import('../views/ProductCategory/Events/AddNewTicketEventReview.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/tourism',
    name: 'Tourism',
    component: () => import('../views/ProductCategory/Tourism/Tourism.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/add-new-tourism-package-detail',
    name: 'New Tourism Details',
    component: () => import('../views/ProductCategory/Tourism/AddNewTourismPackageDetails.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/add-new-tourism-package-itinerary',
    name: 'New Tourism Itinerary',
    component: () => import('../views/ProductCategory/Tourism/AddNewTourismPackageItinerary.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/add-new-tourism-package-price',
    name: 'New Tourism Price',
    component: () => import('../views/ProductCategory/Tourism/AddNewTourismPackagePrice.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/add-new-tourism-package-media',
    name: 'New Tourism Media',
    component: () => import('../views/ProductCategory/Tourism/AddNewTourismPackageMedia.vue'),
    meta: { layout: "authLayout" }
  },
  {
    path: '/add-new-tourism-package-review',
    name: 'New Tourism Review',
    component: () => import('../views/ProductCategory/Tourism/AddNewTourismPackageReview.vue'),
    meta: { layout: "authLayout" }
  },
  { path: '*', redirect: '/login' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login','/signUp','/forgotPassword'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next({ 
      path: '/login', 
      // query: { returnUrl: to.path } 
    });
  }

  next();
})

export default router
