// import Vue from "vue";

export default {
    name: "UserMixins",
    components: {},
    data() {
        return {
            user:{}
        };
    },
    computed: {
    },
    mounted() {
        this.modifyUserData()
    },
    methods: {
        modifyUserData(){
            this.user = JSON.parse(localStorage.getItem('user'))
        }
    }
};