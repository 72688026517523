import axios from 'axios';
const client = axios.create({
    baseURL: process.env.VUE_APP_SERVICE_URL
});

//http interceptor start//
client.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error.response.status == 404);
    if (error.response.status == 404) {
        window.location.href = '/login'
    }
    return Promise.reject(error);
});
//http interceptor end//

export default {
    async execute(method, resource, data) {
        return client({
            method,
            url: resource,
            data,
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem("token")
            }
        })
    },
    async create(data, url) {
        return await this.execute('post', `/${url}`, data);
    },
    async delete(id, url) {
        return await this.execute('delete', `/${url}/${id}`);
    },
    async getById(id, url) {
        return await this.execute('get', `/${url}/${id}`);
    },
    async getAll(url) {
        return await this.execute('get', `/${url}`);
    },
    async update(id, data, url) {
        return await this.execute('put', `/${url}/${id}`, data);
    },
    async createById(id, data, url) {
        return await this.execute('post', `/${url}/${id}`, data);
    },
};