<template>
  <div id="app">
   <component :is="addLayout">
    <router-view />
  </component>
  </div>
</template>
<script>
import defaultLayout from './layouts/DefaultLayout.vue'
export default {
  name: "App",
  computed: {
    addLayout() {
      return this.$route.meta.layout || defaultLayout;
    },
  },
};
</script>

<style lang="scss">

// add font-face because in this case Noto-Sans is taken from Google fonts
@font-face {
  font-family:"FrutigerLTArabic-55Roman";
  src: local("FrutigerLTArabic-55Roman"), local("FrutigerLTArabic-55Roman"),
    url("./assets/fonts/Frutiger_LT_Arabic_55_Roman.ttf") format("truetype");
}
html,body {
    font-family:FrutigerLTArabic-55Roman !important;
    overflow-x: hidden;
    margin:0px !important;
}

</style>

