import Vue from 'vue'
import Vuex from 'vuex'
import Login from './modules/Login'
import Registration from './modules/Login'
import Product from './modules/Product'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    email:null,
    user: null,
    token: null
},
getters: {
  user: (state) => {
    return state.user;
  },
  token: (state) => {
      return state.token;
  },
  email: (state) => {
    return state.email;
  },
},
mutations: {
    user(state, user) {
    state.user = user;
    },
    token(state, user) {
        state.token = user;
    },
    email(state, email) {
      state.email = email;
  },
},
actions: {
    email(context, email) {
        context.commit("email", email);
    },
    user(context, user) {
      context.commit("user", user);
    },
    token(context, token) {
      context.commit("token", token);
    },
},
modules: {
  Product: Product
},
});

export default store;
