import api from '@/api/api';

interface Activitydetails {
    activity_name: "",
    location: "",
    location_url: "",
    description: "",
    policies: "",
    targeted_audience: "Adults & Children",
    addons: []
}

interface Schedule {
    start_date: "",
    end_date: "",
    availabilities: []
}


interface ids {
    ids: []
}


export default {
    state: {
        type: <String>null,
        activityDetailsForm: <Activitydetails>null,
        scheduleDetails: <Schedule>null,
        media_ids: <ids>null,
    },
    getters: {
        getTypeProduct(state: any) {
            return state.type;
        },
        getActivityDetails(state: any) {
            return state.activityDetailsForm;
        },
        getScheduleDetails(state: any) {
            return state.scheduleDetails;
        },
        getMediaDetails(state: any) {
            return state.media_ids;
        }
    },
    mutations: {
        updateProductType(state: any, result: any) {
            state.type = result;
        },
        updateActivityDetails(state: any, result: any) {
            state.activityDetailsForm = result;
        },
        updateScheduleDetails(state: any, result: any) {
            state.scheduleDetails = result;
        },
        updateMediaDetails(state: any, result: any) {
            state.media_ids = result;
        },
        resetStates(state: any) {
            state.type = null;
            state.activityDetailsForm = null;
            state.scheduleDetails = null;
            state.media_ids = null;
        }
    },
    actions: {
        saveProductType({ commit }: any, payload: any) {
            commit('updateProductType', payload);
            return true;
        },
        saveActivityDetails({ commit }: any, payload: any) {
            commit('updateActivityDetails', payload);
            return true;
        },
        saveScheduleDetails({ commit }: any, payload: any) {
            commit('updateScheduleDetails', payload);
            return true;
        },
        saveImageIds({ commit }: any, payload: any) {
            commit('updateMediaDetails', payload);
            return true;
        },
        resetState({ commit }: any) {
            commit('resetStates');
            return true;
        },
        getProductDetails({ commit }: any, payload: any) {
            return new Promise((resolve, reject) => {
                api.getById(payload.id, payload.url).then((x) => {
                    commit('updateProductType', x.data.data.products[0].type);
                    commit('updateActivityDetails',
                        {
                            activity_name: x.data.data.products[0].activity_name,
                            location: x.data.data.products[0].location_name,
                            location_url: x.data.data.products[0].location_url,
                            description: x.data.data.products[0].description,
                            policies: x.data.data.products[0].policies,
                            targeted_audience: x.data.data.products[0].targeted_audience,
                            addons: x.data.data.addons
                        }
                    );
                    commit('updateScheduleDetails', {
                        start_date: x.data.data.products[0].start_date,
                        end_date: x.data.data.products[0].end_date,
                        availabilities: x.data.data.availabilities
                    });
                    commit('updateMediaDetails', {
                        ids: x.data.data.media
                    });
                    resolve(x);
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    }
}
