





























































































































import Vue from "vue";
import UserMixins from "@/common/mixins/userData";

export default Vue.extend({
  name: "Sidebar",
  mixins: [UserMixins],
  data() {
    return {
      notifications: [],
      options: ["Logout"],
    };
  },
  computed: {
    getLockIcon() {
      if (localStorage.getItem("disabled") == "true") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getNotification();
    this.$forceUpdate();
    this.colorLink("0", "/" + window.location.href.split("/").pop());
  },
  methods: {
    async getNotification() {
      await this.$API
        .getAll("vendor/notifications")
        .then((response) => {
          // console.log(response);
          this.notifications = response.data.data.notifications;
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message);
        });
    },
    formatDate(date) {
      var monthName = new Date(date).getMonth() + 1;
      return (
        new Date(date).getDate() +
        "/" +
        monthName +
        "/" +
        new Date(date).getFullYear()
      );
    },
    showNavbar() {
      // document.getElementById("header-toggle")?.classList.toggle("bx-x");
      document.getElementById("nav-bar")?.classList.toggle("show1");
      document.getElementById("body-pd")?.classList.toggle("body-pd");
      document.getElementById("header")?.classList.toggle("body-pd");
      document.getElementById("manualWidth")?.classList.toggle("component-pd");
    },

    colorLink(e: any, route) {
      if (this.$router.currentRoute.fullPath != route)
        this.$router.push({ path: route });
      this.linkColor = document.querySelectorAll(".nav_link");
      if (this.linkColor) {
        this.linkColor.forEach((l: any) => {
          l.classList.remove("active");
          if (
            l.outerText.toLowerCase() == route.split("/").pop().toLowerCase()
          ) {
            l.classList.add("active");
          }
        });
        // this.linkColor[e].classList.add("active");
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
  },
});
