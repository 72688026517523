















import Vue from "vue";
import Header from "@/common/components/Header.vue"; // @ is an alias to /src
import Footer from "@/common/components/Footer.vue"; // @ is an alias to /src
import Sidebar from "@/common/components/Sidebar.vue"; // @ is an alias to /src

export default Vue.extend({
  name: "DefaultLayout",
  components: {
    Header,
    Footer,
    Sidebar,
  },
});
