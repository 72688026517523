  import Vue from 'vue'
  import App from './App.vue'
  import './registerServiceWorker'
  import router from './router'
  import store from './store'
  import DefaultLayout from './layouts/DefaultLayout.vue';
  import AuthLayout from './layouts/AuthLayout.vue';
  import BootstrapVue from './plugins/bootstrapVue'
  import api from '@/api/api';
  import Toasted from 'vue-toasted';
  import moment from "moment";

  import * as VeeValidate from "vee-validate";
  import {
    ValidationProvider,
    ValidationObserver,
  } from "vee-validate/dist/vee-validate.full";

  Vue.prototype.$Moment = moment;
  Vue.prototype.$API = api;
  Vue.config.productionTip = false

  Vue.component('defaultLayout', DefaultLayout)
  Vue.component('authLayout', AuthLayout)
  Vue.use(BootstrapVue);
  Vue.use(Toasted, {
    position: 'top-center',
    duration: 3000,
    keepOnHover: true,
  });
  Vue.component("ValidationObserver", ValidationObserver);
  Vue.component("ValidationProvider", ValidationProvider);

  const v = new Vue({
    router,
    store,
    render: h => h(App)
  });

  router.onReady(() => {
    v.$mount('#app');
  });
